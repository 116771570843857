import React, { useState } from "react";
import { push as Menu } from "react-burger-menu";
import { Link } from "react-router-dom";
import "./Sidebar.css";

const Sidebar = () => {
  const [isMenuOpen, handleMenu] = useState(false);
  const handleCloseMenu = () => {
    handleMenu(false);
  };
  const handleStateChange = (state) => {
    handleMenu(state.isOpen);
  };
  return (
    <React.Fragment>
      <Menu className="Sidebar-container"
        pageWrapId={"page-wrap"}
        isOpen={isMenuOpen}
        onStateChange={handleStateChange}
	    right
      >
          <Link className="Sidebar-link" onClick={() => handleCloseMenu()} to="/learn-about-systm">
            Learn About Sys.tm
          </Link>

          <Link className="Sidebar-link" onClick={() => handleCloseMenu()} to="/how-might-systm-help-you">
           How Might Sys.tm Help You
          </Link>

          <Link className="Sidebar-link" onClick={() => handleCloseMenu()} to="/pricing">
           Pricing
          </Link>

		  <Link className="Sidebar-link" onClick={() => handleCloseMenu()} to="/resources">
           Resources
          </Link>

          <Link className="Sidebar-link" onClick={() => handleCloseMenu()} to="/contact">
            Contact and About Us
          </Link>

	    
		  <a href="https://www.sys.tm/signin" className="Sidebar-link bm-border-none" >Sign In
		  </a>
        
      </Menu>
    </React.Fragment>
  );
};

export default Sidebar;
import React from 'react';
import '../styles/Resources.css';

import Footer from '../Footer/Footer.js';



const articles = [
	{
	   id: '1',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm'],
	   title: 'Sys.tm - Data Sheet',
	   extrenal_link: 'https://www.digitechsystems.com/wp-content/uploads/2024/09/Systm-Product-Sheet.pdf',
	   grabber_question: 'Information overload?',
	   excerpt: 'A single information management platform for digization + office automation',
       downloads: '',
	   feature_image: 'Resources-id1'
	},
	{
	   id: '2',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm'],
	   title: 'Sys.tm - Analyst Summary',
	   extrenal_link: 'https://www.digitechsystems.com/wp-content/uploads/2024/09/How-Will-Systm-Microservices-Change-Your-Business.pdf',
	   grabber_question: 'How Will Sys.tm Microservices Change Your Business?',
	   excerpt: 'Digitech Systems’ new Sys.tm cloud services platform uses microservices and microtransactions-based pricing to simplify how information management and automation tools are purchased and delivered.',
       downloads: '',
	   feature_image: 'Resources-id2'
	},
	{
	   id: '3',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm'],
	   title: 'Sys.tm - Price Sheet',
       extrenal_link: 'https://www.digitechsystems.com/wp-content/uploads/2024/02/Systm-Pricing-Sheet.pdf',
	   grabber_question: 'Oversized documents a big pain?',
	   excerpt: 'View the information below for pricing and features for Sys.tm Standard, Sys.tm Professional and Sys.tm Automations. Annual discounts and volume discounts may be available.',
       downloads: '',
	   feature_image: 'Resources-id3'
	},
	{
	   id: '4',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm'],
	   title: 'Sys.tm - Product Sheet',
       extrenal_link: 'https://www.digitechsystems.com/wp-content/uploads/2024/09/Systm-Product-Sheet.pdf',
	   grabber_question: 'Oversized documents a big pain?',
	   excerpt: 'Learn about Image One’s success in providing customers secure anytime access to digitized large documents.',
       downloads: '',
	   feature_image: 'Resources-id1'
	},
	{
	   id: '5',
	   tags: ['Microservices & Microtransactions-based Billing', 'Sys.tm'],
	   title: 'Sys.tm - Product Sheet',
       extrenal_link: 'https://www.digitechsystems.com/wp-content/uploads/2024/09/Systm-Product-Sheet.pdf',
	   grabber_question: 'Oversized documents a big pain?',
	   excerpt: 'Learn about Image One’s success in providing customers secure anytime access to digitized large documents.',
       downloads: '',
	   feature_image: 'Resources-id2'
	}
	
	
]



const Resources = () => {
	
	 
	return(
		
	  <section className="Resources-main-container">
		
		<div className="Resources-promo-subcontainer">
		<a href="https://www.sys.tm/signup" className="sign-in-nav" aria-label="Sign up now button"><p>Limited Time: FREE for 30 Days. Sign Up Now</p></a>
	    </div>
		
		<section className="Resources-welcome-container">
		
		  <div className="Resources-main-left">
		    <h1>Resources</h1>
		    <p>Learn more about Systm and how it can benefit your business by exploring any of the resources below.</p>
		  </div>
		  <div className="Resources-main-right">
		  </div>
		</section>
		
		<section className="Resources-main-article">
		
		  <section className="Resources-article-container">
			{articles.map((article) => {
		
			return (
			
				<div className="Resources-article-item">
				  <div className={article.feature_image}></div>
				  <div className="Resources-article-text">
					  <h2>{article.title}</h2>
				
				<div className="Resources-article-maintag">
				  {article.tags.map((tag) => {
				
				return (
					<div className="Resources-article-tagcontainer">
					  <p>{tag}</p>
					</div>
				)
			})}
				</div>
				
				  	<p className="Resources-article-grebber"><b>{article.grabber_question}</b></p>
				    <p className="Resources-article-excerpt">{article.excerpt}</p>
					<a className="Resources-article-linkbutton" href={article.extrenal_link} target="_blank" rel="noopener noreferrer">Learn More</a>
				  </div>
				</div>	
			)
	})}
		    </section>

		  </section>
		
		<Footer/>
			
	  </section>
	)
	
}

export default Resources;
import React from 'react';
import '../Ebooks/Ebook.css';

import Footer from '../Footer/Footer.js';

const QuickStartGuide = () => {
	
	return (
		
	  <section className="Ebook-main-container">
		
		<div className="Navbar-promo-subcontainer">
		<a href="https://www.sys.tm/signup" className="sign-in-nav" aria-label="Sign up for free" ><p>Limited Time: FREE for 30 Days. Sign Up Now</p></a>
	    </div>
		
		<div className="Ebook-main-titlecontainer">
		  <h1>Quick Start Guide: Get Started with Sys.tm<sup>®</sup> in Five Minutes</h1>
		  <p className="Ebook-main-p">You’ll be amazed to see just how easy it is to use. Follow these steps to get your
organization up and running with Sys.tm in just five minutes!</p>
		</div>
		
		<section className="Ebook-form-container">
		  <div className="Ebook-formContainer-left">
		    <img className="Ebook-formContainer-img" alt="ebooks promo image" src="https://www.digitechsystems.com/wp-content/uploads/2024/10/quick-start-cover.png" width="" height="380"/> 
		  </div>
		
		  <div className="Ebook-formContainer-right">
		    <h4>This FREE Quick start guide will help you understand:</h4>
		
		    <div className="Ebook-listItem-container">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" alt="ebooks promo image" width="18" height="16"/>
		      <p>How to set up Multi Factor Authentication (MFA) in Sys.tm</p>
		    </div>
		
		    <div className="Ebook-listItem-container">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" alt="ebooks promo image" width="18" height="16"/>
		      <p>How to create a new organization and invite team members</p>
		    </div>
	
			<div className="Ebook-listItem-container">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" alt="ebooks promo image" width="18" height="16"/>
		      <p>How to upload files and folders to Sys.tm</p>
		    </div>

		<div className="Ebook-iframe-maincontainer">
		
		  <iframe className="Ebook-iframe-container" id="" title="" width="" height="" scrolling="no" src="https://go.digitechsystems.com/Systm-Program_systm-gated-quickstart.html"></iframe>
		
		</div>
		
		  </div>
		</section>
		
		<section className="Ebook-promo-container">
		  <div className="Ebook-promo-subcontainer">
		      <h2>Try Sys.tm FREE for 30 Days</h2>
		      <p>Save Money with the Most Flexible, Affordable Product on the Market</p>
	

		      <a href="https://www.sys.tm/signup" aria-label="Try for free button" ><button className="Ebook-promo-button" >Try It Free!</button></a>

		  </div>
		</section>
		
		<section className="Products-HK-container">
		<img className="Learn-HK-img" src="https://www.digitechsystems.com/wp-content/uploads/2024/03/hk-cutpic-color.png" width="120" height="120"/>  
		<div className="Products-HK-textcontainer">
		
		 <div className="Products-HK-item">	 
		  
		  <h5>“Sys.tm brings business information to life. Instead of languishing in boxes, critical data is securely managed and safely accessible to workers in a user-friendly, automated platform. Best of all, information doesn’t just sit waiting to be used. Rather, companies can extract data, automate tasks, comply with regulations, and much more within the Sys.tm environment. Sys.tm simplifies the ways we manage an ever-increasing amount of information.”</h5> 
	      </div>
		
	      <em><p>- HK Bain, CEO, Digitech Systems</p></em>
			 </div> 
			  
	  </section>
		
		<Footer />
		
	  </section>
	
	)
}

export default QuickStartGuide;
import React from 'react';
import '../Pricing/Pricing.css';
import Footer from '../Footer/Footer.js';

import { NavLink } from 'react-router-dom';

const Pricing = () => {
	return(
	  <section className="Pricing-main-container">
		
		<div className="Navbar-promo-subcontainer">
		<a href="https://www.sys.tm/signup" className="sign-in-nav" id="signup-button" aria-label="Sign up free button" ><p>Limited Time: FREE for 30 Days. Sign Up Now</p></a>
	    </div>
	
		<div className="pricing-headerbanner-container">
		
		<div className="pricing-welcome-text"> 
		   <h1>Choose The Best Plan For Your Organization</h1>
		   <p className="Pricing-main-subtitle">View the information below for pricing and features for Sys.tm Standard, Sys.tm Professional and Sys.tm Automations. Annual discounts and volume discounts may be available.</p>
		</div>
		
		</div>
		
		<a href="https://www.digitechsystems.com/wp-content/uploads/2024/09/Sys.tm_Launch_Press_Release.pdf" target="_blank" rel="noopener noreferrer" className="Learn-press-release">
			<h3>Introducing Sys.tm: The perfect balance of intelligent information management, value and functionality</h3>
		</a>
		
	    <section className="Pricing-plans">
		
		
		<section className="Pricing-plans-items No-bottom-margin">
		       <h3>Professional</h3>
	           <h4>$55/mo per user*</h4>	
			<section className="Pricing-feature-container">
		  		  <div className="Pricing-feature-itemcontainer top-feature">
		    <h5 className="Pricing-feature-itemtext">File Management</h5>
		    <div className="Pricing-feature-textcontainer">
				<img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
		    </div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Data Security</h5>
		    <div className="Pricing-feature-textcontainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
		    </div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">100 GB Storage (per user)</h5>
			<div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
		    </div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Sys.tm Windows Client</h5>
		    <div className="Pricing-feature-textcontainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Multi Factor Authentication (MFA)</h5>
		    <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Customizable Dashboard Widgets</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Set Folder Access Permissions</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Legendary Technical Support</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Recycle Bin</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
	      <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Version Retention</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Audit Logs</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Content Extraction / Searching</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">File Sharing</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer bottom-feature">
		    <h5 className="Pricing-feature-itemtext">Metadata</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		
		</section>
	
			<a className="text-decoration" href="https://www.sys.tm/signup" aria-label="Try it free button">
		      <button className="welcome-button pricing-page-button">Try It Free!</button>
	 		</a>
	
		  </section>
		
		  <section className="Pricing-plans-items">
		    <h3>Standard</h3>
		    <h4>$30/mo per user*</h4>
		
			<section className="Pricing-feature-container">
		  
		  <div className="Pricing-feature-itemcontainer top-feature">
		    <h5 className="Pricing-feature-itemtext">File Management</h5>
		    <div className="Pricing-feature-textcontainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
	         </div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Data Security</h5>
		    <div className="Pricing-feature-textcontainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
		    </div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">100 GB Storage (per user)</h5>
			<div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
		    </div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Sys.tm Windows Client</h5>
		    <div className="Pricing-feature-textcontainer">
		      <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Multi Factor Authentication (MFA)</h5>
		    <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Customizable Dashboard Widgets</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Set Folder Access Permissions</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Legendary Technical Support</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext">Recycle Bin</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/01/check.png" width="18" height="18" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext nonactive">Version Retention</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/02/nonactive.png" width="28" height="3" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext nonactive">Audit Logs</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/02/nonactive.png" width="28" height="3" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext nonactive">Content Extraction / Searching</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/02/nonactive.png" width="28" height="3" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer">
		    <h5 className="Pricing-feature-itemtext nonactive">File Sharing</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/02/nonactive.png" width="28" height="3" alt="Check Icon"/>
			</div>
		  </div>
		  <div className="Pricing-feature-itemcontainer bottom-feature">
		    <h5 className="Pricing-feature-itemtext nonactive">Metadata</h5>
	        <div className="Pricing-feature-textcontainer">
		    <img src="https://www.digitechsystems.com/wp-content/uploads/2024/02/nonactive.png" width="28" height="3" alt="Check Icon"/>
			</div>
		  </div>
		
		</section>
		    <a className="text-decoration" href="https://www.sys.tm/signup" aria-label="Sign up for free button">
		    	<button className="welcome-button pricing-page-button">Try It Free!</button>
	        </a>
		  </section>
		
		  
		
		</section>
	
	      <section className="Pricing-addon">
	        <p>*Available add-ons for Sys.tm Standard: Version Retention - $5/mo, Audit Logs - $7/mo,</p>
			<p>Content Extraction/Searching - $10/mo, File Sharing - $5/mo, Metadata - $10/mo.</p>
			<p>**Additional storage per organization is available for purchase.</p>
		  </section>
	
	
	      <section className="Pricing-RPA-container">
		    <div className="Pricing-RPA-left">
			  <h2>Sys.tm Automations</h2>
	   	      <p>Robotic process automation (RPA)<br />Automate back-office tasks</p>
			</div>
		    <div className="Pricing-RPA-right">
				<h3 className="Pricing-RPA-righttext">RPA prices based on number of clock hours services are run. <br />You are only billed for what you use:</h3>
				<div className="Pricing-RPA-rightitems">
				  <p>Sys.tm Automations Starter</p>
				  <p>$1/month/user</p>
				</div>
				<div className="Pricing-RPA-rightitems">
				  <p>Interactive Agent Automations</p>
				  <p>$0.15/hour/agent</p>
				</div>
				<div className="Pricing-RPA-rightitems bottom-rpa">
				  <p>Unattended Agent Automations</p>
				  <p>$0.40/hour/agent</p>
				</div>
			</div>
	      </section>
	
		  <section className="Pricing-doc-capture">
		    <h5>Intelligent Document Capture Integration: Sys.tm includes prebuilt integration for upload from PaperVision<sup>®</sup> Capture, PaperVision<sup>®</sup> Forms Magic<sup>™</sup>, and PaperFlow<sup>™</sup>.</h5>
		  </section>
	
	
		 	   	<section className="Home-contact-container Pricing-contact-container">
		
		  <div className="Home-contact-items contact-container-left">
		
		    

	
		  </div>
	
	
	
	      <div className="Home-contact-items contact-container-right">
					
					<div className="contact-left-textblock">
		    	<h2>How Can We Help?</h2>
				<p>Sys.tm's advanced solutions are more efficient and save energy to do other things.</p>
			</div>
	
			<div className="Home-contact-iconcontainer">
		
				<div className="Home-contact-subcontainer fast-call">
		        	<div className="Home-contact-fast-call">
						<img className="welcome-bigpicture-img" src="https://www.digitechsystems.com/wp-content/uploads/2024/07/fast-call-wr.png" width="37" height="37" alt="Fast call icon" /> 
		            </div>
					<h5>We’ll give you a call back within 1 business day.</h5>
				  </div>
															
				  <div className="Home-contact-subcontainer demo-call">
		        	<div className="Home-contact-fast-call">
						<img className="welcome-bigpicture-img bpi-bottom" src="https://www.digitechsystems.com/wp-content/uploads/2024/07/live-demo-wr.png" width="37" height="37" alt="demo icon" /> 
		            </div>
					<h5>Explore the platform live with our specialists.</h5>
				  </div>
	
	            <NavLink to="/contact" className="welcome-button text-decortion pricing-contact-button">Contact</NavLink>
		    </div>
					

		  </div>
	
		</section>
				
		<Footer />
	
	  </section>
	)
}

export default Pricing;
import React from 'react';
import Footer from '../Footer/Footer.js';

import '../styles/BecomeaSystmReseller.css';

const BecomeaSystmResellerCopy = () => {
	
	return(
	  <section className="Reseller-container">
		

		    <div>
		    	<h1>Hello Test Site!</h1>
		    </div>  
		
		<iframe className="Test-iframe-container" id="" title="" width="600" height="" scrolling="no" src="https://go.digitechsystems.com/Systm-Program_systm-reseller-landing-page.html" title="Marketo Form"></iframe>
		    
		
		 <Footer />
		
	  </section>
	
	)
}

export default BecomeaSystmResellerCopy;
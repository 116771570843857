import React from 'react';
import '../styles/Accessibility.css';

import Footer from '../Footer/Footer.js';

const Accessibility = () => {
	
	return(
		
	  <section className="Accessibility-container">
		
		<div className="Navbar-promo-subcontainer">
		<a href="https://www.sys.tm/signup" className="sign-in-nav" id="signup-button" aria-label="Sign up for Free promo"><p>Limited Time: FREE for 30 Days. Sign Up Now</p></a>
	    </div>
		
		<div className="Accessibility-headerbanner-container">
		
		  <div className="Accessibility-headerbanner-textcontainer">
		    <h1>Accessibility Statement for ImageSilo<span className="sup-sizing"><sup>®</sup></span>, <br/>PaperVision<span className="sup-sizing"><sup>®</sup></span>.com, Sys.tm<span className="sup-sizing"><sup>®</sup></span> and PaperVision<span className="sup-sizing"><sup>®</sup></span> Enterprise</h1>
		  </div>
		
		</div>
		
		<a href="https://www.digitechsystems.com/wp-content/uploads/2024/09/Sys.tm_Launch_Press_Release.pdf" target="_blank" rel="noopener noreferrer" className="Learn-press-release">
			<h3>Introducing Sys.tm: The perfect balance of intelligent information management, value and functionality</h3>
		</a>
		
		<div className="Accessibility-welcome-container">
		   <h3>WCAG statement</h3>
           <p>Digitech Systems LLC is committed to ensuring digital accessibility for people with disabilities. We are continually improving the user experience for everyone, and applying the relevant accessibility standards.</p>
			
		  <h3>Conformance status</h3>
          <p className="Accessibility-top-para">The <a href="https://www.w3.org/WAI/standards-guidelines/wcag/">Web Content Accessibility Guidelines (WCAG)</a> defines requirements for designers and developers to improve accessibility for people with disabilities. It defines three levels of conformance: Level A, Level AA, and Level AAA. ImageSilo, PaperVision.com, Sys.tm and PaperVision Enterprise are partially conformant with WCAG 2.1 Level AA. Partially conformant means that some parts of the content do not fully conform to the accessibility standard.</p>
		
		  <h3>Feedback</h3> 
		  <p className="Accessibility-top-para">We welcome your feedback on the accessibility of ImageSilo, PaperVision.com, Sys.tm or PaperVision Enterprise. Please let us know if you encounter accessibility barriers on ImageSilo, PaperVision.com, Sys.tm or PaperVision Enterprise:</p>
	
		  <ul className="Accessibility-ul">
		    <li className="Accessibility-li">Phone: 866-374-3569 (Alain Richard – Nathan Schwenke)</li>
			<li className="Accessibility-li">E-mail: Support@digitechsystems.com</li>
			<li className="Accessibility-li">Postal address: 8400 E. Crescent Parkway, Suite 500 Greenwood Village CO 80111</li>
		  </ul>
          <p className="blank-space">&nbsp;</p>
		  <p>We try to respond to feedback within 5 business days.</p>
	
		</div>
		
		<Footer />
	
	  </section>
	
	)
}

export default Accessibility;

import React from 'react';
import '../PrivacyPolicy/Privacypolicy.css';

import Footer from '../Footer/Footer.js';

const PrivacyPolicy = () => {
	
	return(
		
	  <section className="Privacy-container">
		
		<div className="Navbar-promo-subcontainer">
		<a href="https://www.sys.tm/signup" className="sign-in-nav" id="signup-button" aria-label="Sign up for free"><p>Limited Time: FREE for 30 Days. Sign Up Now</p></a>
	    </div>
		
		<div className="Privacy-Headerbanner-container">
		
		  <div className="Privacy-Headerbanner-textcontainer">
		    <h1>Privacy Policy</h1>
		  </div>
		
		</div>
		
		
		<a href="https://www.digitechsystems.com/wp-content/uploads/2024/09/Sys.tm_Launch_Press_Release.pdf" target="_blank" rel="noopener noreferrer" className="Learn-press-release">
			<h3>Introducing Sys.tm: The perfect balance of intelligent information management, value and functionality</h3>
		</a>
		
		
		<div className="Privacy-welcome-container">
		  <h1>Welcome</h1>
           <p>Sys.tm is a cloud-based content management platform that enables you to securely access and easily manage your information, while seamlessly integrating that material into your daily activities – Any Document, Anywhere, Anytime<sup>®</sup>. Sys.tm can be accessed via the web and on nearly any type of device: desktops, laptops, tablets, and smartphones.</p>
		   <p>We care about the security and privacy of your personal data that you entrust to us. This Privacy Policy explains how we collect, use, and handle your personal data, and with whom we may share it. In a nutshell, we will not sell or share your personal data or any of your Content with third parties unless you allow it, or they are one of our Trusted Providers necessary to provide our Services.</p>
           <p>If you have any questions or concerns about this Sys.tm Privacy Policy, please contact us via email at <a className="Privacy-welcome-emailLink" href = "mailto: support@sys.tm">support@sys.tm</a>.</p>
			
		  <h3>What We Collect and Why We Collect It</h3>
          <p className="Privacy-top-para"><span className="Privacy-titleunderline">Account Information:</span> When you provide information to sign up for a Sys.tm account (name, email, etc.), create new Organizations (company name), pay for our Services (billing and credit card information), or setup multi-factor authentication (phone number), we store and associate that information with your account. Your account is retained as long as you maintain a Sys.tm account. You can immediately delete this data by deleting your account via the Sys.tm website (Settings -> Account Settings -> Delete Account).</p>
		  <p><span className="Privacy-titleunderline">Credit Card and Billing Information:</span> We have embedded technology from our trusted third party billing provider (who is a PCI Level 1 Service Provider, the most stringent level of certification available in the payments industry) that collects billing information, including name, email address, company name, address, and credit card information. They alone collect, process, and store your full credit card information, although they do provide us with the credit card type, last four numbers, and card expiration date, which we store for your future use. Your credit card and billing data is retained indefinitely.</p>
		  <p><span className="Privacy-titleunderline">Content:</span> Sys.tm allows you to securely access and easily manage your information and integrate it into your daily activities. While using Sys.tm, you might upload files, provide metadata values to easily locate those files, apply annotations, send messages, and so forth. All of this is your content (“Content”). We access, transmit, process, scan, store, and disclose your Content and other information related to it only as necessary to provide our Services. Your Content is retained until either you (or another authorized user within your organization) permanently deletes the content (or it’s time in the Recycle Bin expires based on your settings) or 30 days after your Organization is deleted.</p>
		  <p><span className="Privacy-titleunderline">Usage Information:</span> We collect information related to how you use our Services, including settings, features used, your sharing of information through Sys.tm, and how you view and access Sys.tm and your Content. We use this usage information to protect you and other users, provide and improve our Services, and promote Sys.tm. Your usage information is retained indefinitely.</p>
		  <p><span className="Privacy-titleunderline">Device Information:</span> We collect information about the devices and browsers you use to access our Services. This includes things like devices (name, type and identifier), operating systems (type and version), the web page you visited before coming to our websites, IP addresses, and web browsers (type and version). We use this device information to secure your account and Content, provide and improve our Services, and identify and troubleshoot issues. Your device information is retained as long as you maintain a Sys.tm account.</p>
		  <p><span className="Privacy-titleunderline">Browser Storage:</span> We use browser storage (local and session) to provide, secure, enhance, and promote our Services. As examples, we use browser session storage to remember your account information while using Sys.tm, and we use browser local storage to store your Sys.tm settings related to that unique computer. Your browser storage is retained by your browser until you explicitly clear it from your browser.</p>
		  <p><span className="Privacy-titleunderline">Browser Cookies:</span> We use browser cookies to ensure our Services function properly, to detect and prevent fraud, and to analyze and improve our Services. Your browser cookies are retained by your browser until you explicitly clear them from your browser.</p>
		  <p><span className="Privacy-titleunderline">Pixel Tags:</span> We use pixel tags to understand how you are interacting with our Services so that we can improve them. Data collected by our use of Pixel Tags is retained indefinitely.</p>
		
		  <h3>With Whom We Share</h3> 
		  <p className="Privacy-top-para"><span className="Privacy-titleunderline">Our Partners:</span> If you are associated with one of our Dealer or Reseller partners, we will share your usage and billing information with them so they are fairly compensated for connecting us to you (Dealers) or so they can pay our Fees related to your Organization’s usage (Reseller).</p>
	      <p><span className="Privacy-titleunderline">Trusted Providers:</span> We engage our trusted providers to help us provide our Services. We partner with Stripe to provide billing and credit card processing (see “Credit Card and Billing Information” above). We also rely on Amazon AWS to provide general computing platforms, networking, infrastructure, storage, database services, processing services, recognition capabilities, and other technical services. These providers will have access to your information to perform tasks on our behalf, and we will be responsible for their handling of your personal data per our instructions. We require them to maintain the same protections over your data that we provide directly.</p>
		  <p><span className="Privacy-titleunderline">Your Sys.tm Organizations:</span> When you accept an invitation to join a Sys.tm Organization (for instance, your employer), we share your account information with that Organization so they can manage your access and security permissions. Your Sys.tm Organization(s) can also reset your multi-factor authentication, view your usage data within the Organization (including how and when your account is used), and manage any Content or information you create within the Organization’s account.</p>
		  <p><span className="Privacy-titleunderline">Other Users:</span> Some features of our Services allow you to share specific information, including files, with others Sys.tm and non-Sys.tm users. In addition to your specified information, your name, email address, and Organization may be shared with those parties.</p>
		  <p><span className="Privacy-titleunderline">Third Parties and Applications:</span> Sys.tm offers extensive APIs that allow third parties to write applications that interact with our Services. You can choose to utilize these applications and allow them to access your Sys.tm account. In doing so, you enable us to exchange information with those third parties, including account information, Content, and usage information. Keep in mind that their use of your personal data is governed by their own privacy policies and terms of service.</p>
		  <p><span className="Privacy-titleunderline">Us:</span> We store and process your personal information only to provide our Services. We will not access or use your Content except as necessary to maintain or provide our Services, or to confirm compliance with our Acceptable Use Policy.</p>
		  <p><span className="Privacy-titleunderline">Legal Requirements:</span> We may disclose your personal data, including Content, if required by law or regulation. We will attempt to give reasonable advance notice, to the extent permitted, so that you can seek to prevent or limit such disclosure.</p>

		</div>
		
		<div className="Legal-container">
		
		</div>
		
		< Footer/>

	  </section>
	
	)
}

export default PrivacyPolicy;

import React from 'react';
import { NavLink } from 'react-router-dom';


import './Footer.css';

const Footer = () => {
	
	return(
	  <section className="Footer">
		
	    <div className="Footer-subcontainer">
		
	      <div className="Footer-linkcontainer">
		
		    <div className="Footer-cat-container">
		      <a className="nav Non-active" href="https://www.sys.tm/welcome"><p>Home</p></a>
	          <NavLink to="/learn-about-systm" className="nav Non-active"><p>Learn About Sys.tm</p></NavLink>
			  <NavLink to="/how-might-systm-help-you" className="nav Non-active"><p>How Might Sys.tm Help You</p></NavLink>
		      <NavLink to="/pricing" className="nav Non-active"><p>Pricing</p></NavLink>
		       <p className="blank-space">&nbsp;</p>
		      <NavLink to="/privacy" className="Footer-privacy Non-active"><p>Privacy</p></NavLink>
			  <NavLink to="/accessibility" className="Footer-privacy Non-active"><p>Accessibility </p></NavLink>
		      <NavLink to="/become-a-systm-reseller" className="Footer-privacy Non-active"><p>Become a Sys.tm Reseller</p></NavLink>
		    </div>
		    <div className="Footer-contact-container">
		      <NavLink to="/contact" className="nav Non-active"><p>Contact and About Us</p></NavLink>
		      <a className="nav Non-active" href="tel:+18663743569"><p>1-866-374-3569</p></a>
		      <a className="nav Non-active" href="mailto: support@sys.tm"><p>Email Support</p></a>
		      <a className="nav Non-active" href="mailto: sales@sys.tm"><p>Sales Support</p></a>
		      <p className="blank-space">&nbsp;</p>
		      <a className="nav Non-active" href="https://www.sys.tm/signin"><p>Sign In</p></a>
			  <p className="blank-space">&nbsp;</p>
		      <p className="Footer-copyright"><span className="Footer-text">Copyright © 2024 Digitech Systems, LLC. All rights reserved. < br/>Sys.tm® and PaperVision® are registered trademarks of Digitech Systems, LLC.</span></p>
		    </div>
		
		   </div>
		
	      </div>
		
	  </section>
	
	)
}

export default Footer;
import React from 'react';
import '../styles/Contact.css';

import Footer from '../Footer/Footer.js';

const Contact = () => {
	
//	const handlePardotSubmit = (e) => {
//	  e.preventDefault();
//      console.log(e);
//		
//	  const form = e.target;
//      const formData = new FormData(form);
//		
//	  const formJson = Object.fromEntries(formData.entries());
//    console.log(formJson);	
//		
//		
//    };
	
	return(
	  <section className="Contact-main-container">
		
		<div className="Navbar-promo-subcontainer">
		<a href="https://www.sys.tm/signup" className="sign-in-nav" id="signup-button" ><p>Limited Time: FREE for 30 Days. Sign Up Now</p></a>
	    </div>
		
		<div className="Contact-Headerbanner-container">
		
		  <div className="HowMightSystmHelpYou-Headerbanner-textcontainer">
		    <h1>Contact and About Us</h1>
			<p>Our best in support are a click away to help you get the most out of Sys.tm.</p>
		  </div>
		
		</div>
		
		
		  <a href="https://www.digitechsystems.com/wp-content/uploads/2024/09/Sys.tm_Launch_Press_Release.pdf" target="_blank" rel="noopener noreferrer" className="Learn-press-release">
			<h3>Introducing Sys.tm: The perfect balance of intelligent information management, value and functionality</h3>
		  </a>
		
		
		<section className="Contact-welcome-container">
		
		<div className="Contact-welcome-subcontainer Contact-welcome-rightsub">
		
		  <div className="Contact-welcome-textcontainer">
		    <h3>Who is Digitech Systems?</h3>
			<p>We know you probably want to know a little more about the company that’s bringing you Sys.tm. Here’s our story, in brief. 
				If you’d like to learn more about our Values Foundation, view an interactive timeline of our history, or find employment 
				opportunities, please visit <a href="https://www.digitechsystems.com/about/">www.DigitechSystems.com/About</a>.</p>
				<p>For more than 27 years, Digitech Systems has been helping companies save time and money on information 
				management. Whether its converting paper files to electronic, ensuring the data security of sensitive information, 
				extracting data from forms to eliminate manual data entry, streamlining business processes, or supporting hybrid work 
				and collaboration, we’re here for you!</p>
		  </div>
		
		  <div className="Contact-welcome-textcontainer">
		  	<h3 className="Contact-welcome-op">Our product suite includes:</h3>
		  		<ul>
				  <li>Scanning and capture of paper documents (where we started back in 1997).</li>
		    	  <li>Enterprise Content Management (ECM) both on premise and in the Cloud since 1999 (You read that right!
				We’re passionate about smart innovation and had thousands of customers on our cloud service years before the model was even named “cloud”. You choose what fits your needs.).</li>
				  <li>Process and Task Automation (also called workflow and Robotic Process Automation (RPA)).</li>
				  <li>Artificial Intelligence based data extraction (supported by 3 of our own unique patents).</li>
		  		</ul>
		  </div>
	
		</div>
	<img className="Contact-welcome-subcontainer Contact-welcome-leftsub Contact-group-img" src="https://www.digitechsystems.com/wp-content/uploads/2024/08/2024-Digitech-Group-Photo-wr.webp" width="" height="" alt="Team photo" />
		</section>
	
		<section className="Contact-subcontainer">
			
		  <div className="Contact-left-container">  
			<div className="Form-wrapper-container">
				  
				  <h4 className="Form-wrapper-h4">Interested in Sys.tm?, <br />We'd love to hear from you!</h4>
				  
				  <iframe className="Test-iframe-container" id="" title="" width="600" height="" scrolling="no" src="https://go.digitechsystems.com/Systm-Program_test-landingpage.html" title="Marketo Form"></iframe>
			     
					
			</div>
		  </div>
	
		  <div className="Contact-right-container">
				
			  <div className="Contact-container">
			  	<h4>Technical Support:</h4>
			  	<h5>Toll-Free:</h5>
			  	<p><a className="contact-link" href="tel:866-374-3569">866-369-3569</a></p>
			  	<h5>International:</h5>
			  	<p><a className="contact-link" href="tel:303-493-6900">303-493-6900</a></p>
			  	<h5>Email:</h5>
			  	<p><a className="contact-link" href="mailto:support@sys.tm">support@sys.tm</a></p>
		
			  	<h4 className="Contact-top-title contact-sales-title">Sales and Reseller <br />Program Information:</h4>
			  	<h5>Toll-Free:</h5>
			  	<p><a className="contact-link" href="tel:866-374-3569">866-374-3569</a></p>
			  	<h5>International:</h5>
			  	<p><a className="contact-link" href="tel:1.303-493-6900">+1 303-493-6900</a></p>
			  	<h5>Email:</h5>
			  	<p><a className="contact-link" href="mailto:sales@sys.tm">sales@sys.tm</a></p>
		     </div>
				
             </div>
	
	      
		</section>  
	
	   <Footer />
		
	  </section>
	)
}

export default Contact;